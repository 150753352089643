import React from "react";
import Container from "@material-ui/core/Container";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

const useStyles = makeStyles((theme) => ({
  accordion: {
    // Remove shadow
    boxShadow: "none",
    "&:before": {
      // Remove default divider
      display: "none",
    },
    // Add a custom border
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  expanded: {
    margin: `0 !important`,
  },
  summary: {
    minHeight: 78,
  },
  summaryContent: {
    margin: "0 !important",
  },
}));

function FaqSection(props) {
  const classes = useStyles();

  const items = [
    {
      question: "What is this?",
      answer:
        "For Emailer is a platform to send customizable, schedulable emails to yourself, or others (whether you're building an audience, or inspiring a team). There are not many other options for sending reoccurring scheduled emails without coming up with a highly technical solution. We hope to ease the burden, and allow you to communicate in a medium that meets people where they're at. With email in our pocket, most people are checking their email multiple times per day.",
    },
    {
      question: "Who made it?",
      answer:
        "As a husband, father, and mid-level manager in IT, I found myself often creating technical solutions to send surveys to measure progress on my goals, as reminders that I didn't want to get buried, or to communicate/motivate my team at work. I created a similar bespoke solution for my dad to send out a daily email to a group of people he was communicating with. I'm passionate about efficiency, optimization, and automation and wanted to create a service that I could continue to leverage, and build content around to help other small-businesses on their automation journeys.",
    },
    {
      question: "Do you need more emails or recipients than the Team plan allows?",
      answer:
        "Head over to the contact form, and we'd be happy to discuss pricing with you for larger plans.",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="md">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        {items.map((item, index) => (
          <Accordion
            classes={{
              root: classes.accordion,
              expanded: classes.expanded,
            }}
            key={index}
          >
            <AccordionSummary
              classes={{
                root: classes.summary,
                content: classes.summaryContent,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`faq-panel-${index}`}
            >
              <Typography variant="h4">{item.question}</Typography>
            </AccordionSummary>
            <AccordionDetails id={`faq-panel-${index}`}>
              <Typography>{item.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </Section>
  );
}

export default FaqSection;
