const stripePriceIds = process.env.TAKE_PAYMENT === "true" ? {
  individual: process.env.REACT_APP_STRIPE_PRICE_INDIVIDUAL,
  // pro: process.env.REACT_APP_STRIPE_PRICE_PRO,
  team: process.env.REACT_APP_STRIPE_PRICE_TEAM,
} : {
  STRIPE_PRICE_INDIVIDUAL: process.env.REACT_APP_STRIPE_PRICE_INDIVIDUAL_TEST,
  STRIPE_PRICE_TEAM: process.env.REACT_APP_STRIPE_PRICE_TEAM_TEST,
};

// Get Stripe priceId
export function getStripePriceId(planId) {
  return stripePriceIds[planId];
}

// Get friendly plan ID ("basic", "premium", etc) by Stripe plan ID
// Used in auth.js to include planId in the user object
export function getFriendlyPlanId(stripePriceId) {
  return Object.keys(stripePriceIds).find(
    (key) => stripePriceIds[key] === stripePriceId
  );
}
