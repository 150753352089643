import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import LinkMui from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import DashboardItems from "./DashboardItems";
import { Link, useRouter } from "./../util/router";
import { useAuth } from "./../util/auth";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3),
  },
}));

function DashboardSection(props) {
  const classes = useStyles();

  const auth = useAuth();
  const router = useRouter();


  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        {router.query.user && router.query.item && (
          <Box mx="auto" mb={4} maxWidth={400}>
            <Alert severity="success">
              Got this response from the email. Need to trigger api call.
              <span
                role="img"
                aria-label="party"
                style={{ marginLeft: "10px" }}
              >
                🥳
              </span>
            </Alert>
          </Box>
        )}
        {router.query.paid && auth.user.planIsActive && (
          <Box mx="auto" mb={4} maxWidth={400}>
            <Alert severity="success">
              You are now subscribed to the {auth.user.planId} plan
              <span
                role="img"
                aria-label="party"
                style={{ marginLeft: "10px" }}
              >
                🥳
              </span>
            </Alert>
          </Box>
        )}

        <Grid container={true} spacing={4}>
          <Grid item={true} xs={12} md={6}>
            <DashboardItems />
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <Paper className={classes.paperItems}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding={2}
              >
                <Typography variant="h5">Plan</Typography>
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  href="/settings/general"
                >
                  Edit Plan
                </Button>
              </Box>
              <Divider />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding={2}
              >

                <Typography component="div">
                  <div>
                    User <strong>{auth.user.email}</strong>.
                  </div>

                  {auth.user.stripeSubscriptionId && (
                    <>
                      <div>
                        You are subscribed to the{" "}
                        <strong>{auth.user.planId} plan</strong>.
                      </div>
                      <div>
                        Your plan status is{" "}
                        <strong>
                          {auth.user.stripeSubscriptionStatus}
                        </strong>
                        .
                      </div>
                    </>
                  )}
                  {!auth.user.stripeSubscriptionId && (
                    <div>
                      You can signup for a plan in{" "}
                      <LinkMui component={Link} to="/pricing">
                        <strong>pricing</strong>
                      </LinkMui>
                      .
                    </div>
                  )}
                </Typography>
              </Box>
            </Paper>
          </Grid >
        </Grid >
      </Container >
    </Section >
  );
}

export default DashboardSection;
