import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

const useStyles = makeStyles((theme) => ({
  itemsContainer: {
    marginTop: 60,
  },
  row: {
    // Reverse every other row
    "&:nth-of-type(even)": {
      flexDirection: "row-reverse",
    },

    // Spacing between rows
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(3)}px`,
    },
  },
  figure: {
    maxWidth: 300,
    margin: "30px auto",
  },
  image: {
    height: "auto",
    maxWidth: "100%",
  },
}));

function FeaturesSection(props) {
  const classes = useStyles();

  const items = [
    {
      title: "Customizable",
      description:
        "~350 billion emails are sent every day. We won't box you in.",
      image: "https://uxwing.com/wp-content/themes/uxwing/download/signs-and-symbols/finger-print-icon.png",
    },
    {
      title: "One to Many Recipients",
      description:
        "Want a unique newsletter for your unique interests? Want to motivate your team? Building an audience you want an interactive platform for?",
      image: "https://uxwing.com/wp-content/themes/uxwing/download/business-professional-services/team-icon.png",
    },
    {
      title: "One-Tap Surveys",
      description:
        "Want to keep yourself accountable to daily goals? Want a low-friction way to check-in with your team? Want your audience to give feedback to your content?",
      image: "https://uxwing.com/wp-content/themes/uxwing/download/business-professional-services/column-chart-icon.png",
    },
    {
      title: "Schedulable",
      description:
        "Options for daily, weekly, monthly delivery to check in with yourself, your team, or your audience at any frequency.",
      image: "https://uxwing.com/wp-content/themes/uxwing/download/time-and-date/calendar-line-icon.png",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Container
          maxWidth="md"
          disableGutters={true}
          className={classes.itemsContainer}
        >
          {items.map((item, index) => (
            <Grid
              className={classes.row}
              container={true}
              item={true}
              alignItems="center"
              spacing={4}
              key={index}
            >
              <Grid item={true} xs={12} md={6}>
                <Box
                  textAlign={{
                    xs: "center",
                    md: "left",
                  }}
                >
                  <Typography variant="h5" gutterBottom={true}>
                    {item.title}
                  </Typography>
                  <Typography variant="subtitle1">
                    {item.description}
                  </Typography>
                </Box>
              </Grid>
              <Grid item={true} xs={12} md={6}>
                <figure className={classes.figure}>
                  <img
                    src={item.image}
                    alt={item.title}
                    className={classes.image}
                  />
                </figure>
              </Grid>
            </Grid>
          ))}
        </Container>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
